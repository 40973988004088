import { Component, OnInit, ViewChild } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, NgForm, Validators } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { map } from "rxjs/operators";
import { FetchDataService } from "src/app/core/services/fetch-data.service";

@Component({
    selector: "app-register",
    templateUrl: "./register.component.html",
    styleUrls: ["./register.component.scss"]
})
export class RegisterComponent implements OnInit {
    registerForm: FormGroup;
    verifyLink;
    verifyId;
    countryCodesList = [];
    registerType;
    @ViewChild("formDir") private formDir: NgForm;
    responseMessage;
    errorMessage;
    email;
    id;
    type;
    constructor(private fb: FormBuilder, private fd: FetchDataService, private ad: ActivatedRoute, private router: Router) {}

    ngOnInit(): void {
        this.getCountryCodes();
        this.ad.queryParamMap.subscribe((params: Params) => {
            this.email = params.params.email;
            this.id = params.params.id;
            this.type = params.params.type;
        });
        this.registerForm = this.fb.group(
            {
                id: this.id,
                email: [this.email, [Validators.required, Validators.email]],
                password: ["", [Validators.required, Validators.minLength(4)]],
                rpassword: ["", Validators.required],
                first_name: ["", [Validators.required, Validators.minLength(3)]],
                last_name: ["", [Validators.required, Validators.minLength(3)]],
                country: ["", Validators.required],
                country_code: ["", Validators.required],
                phone: ["", [Validators.required]],
                register_as: ["", Validators.required],
                type: [this.type, Validators.required]
            },
            { validator: this.passwordConfirming }
        );
        this.ad.queryParamMap.subscribe((params: Params) => {
            this.verifyLink = params.params.h;
            //console.log(params);
            this.verifyEmail();
        });

        // var radio = document.querySelector('.radio');
        //    var inputs = document.querySelector('.inputs');

        //     radio.addEventListener('click', function(){
        //             inputs.classList.toggle('hidden')
        //                  })


    }

    verifyEmail() {
        const formData = new FormData();
        formData.append("h", this.verifyLink);
        this.fd.verifyEmail(formData).subscribe((res) => {
            console.log(res);
            if (res.code == 1) {
                this.registerType = res.data.type;
                if (res.code == 1) {
                    this.verifyId = res.data.id;
                    console.log(this.verifyId);
                    this.registerForm.patchValue({
                        id: res.data.id,
                        email: res.data.email,
                        type: res.data.type
                    });
                }
            }
        });
    }
    getCountryCodes() {
        this.fd
            .getCountryCodes()
            .pipe(
                map((data: any) => {
                    this.countryCodesList = data.result;
                    console.log(data);
                })
            )
            .subscribe();
    }
    passwordConfirming(c: AbstractControl): { invalid: boolean } {
        if (c.get("password").value !== c.get("rpassword").value) {
            return { invalid: true };
        }
    }
    isNumber(evt) {
        evt = evt ? evt : window.event;
        var charCode = evt.which ? evt.which : evt.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }
    onSelectCountry(item) {
        console.log(item);
        let indx = this.countryCodesList.findIndex((m) => m.name === item);
        this.registerForm.patchValue({
            country_code: this.countryCodesList[indx]["phonecode"]
        });
    }
    onSubmitRegisterForm() {
        console.log(this.registerForm.value);
        window.scrollTo({ top: 0, behavior: "smooth" });
        // let responseMsg = document.getElementById("responseMsg");
        if (this.registerForm.valid) {
            const formData = new FormData();
            formData.append("id", this.registerForm.get("id").value);
            formData.append("email", this.registerForm.get("email").value);
            formData.append("password", this.registerForm.get("password").value);
            formData.append("rpassword", this.registerForm.get("rpassword").value);
            formData.append("first_name", this.registerForm.get("first_name").value);
            formData.append("last_name", this.registerForm.get("last_name").value);
            formData.append("country", this.registerForm.get("country").value);
            formData.append("country_code", this.registerForm.get("country_code").value);
            formData.append("phone", this.registerForm.get("phone").value);
            formData.append("register_as", this.registerForm.get("register_as").value);
            formData.append("type", this.registerForm.get("type").value);
            this.fd.signup(formData).subscribe((res) => {
                if (res.code == 1) {
                    this.responseMessage = res.message;
                    setTimeout(() => {
                        this.router.navigate(["login"]);
                    }, 3000);
                } else {
                    this.errorMessage = "Please try Again";
                }
            });
        }
    }
}
