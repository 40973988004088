import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ChatService } from '../core/services/chat.service';
import { ChatsubjectService } from '../core/services/chatsubject.service';

import { EventService } from '../core/services/event.service';
import { SellerService } from '../core/services/seller.service';

import {
  LAYOUT_VERTICAL, LAYOUT_HORIZONTAL, LAYOUT_WIDTH, TOPBAR
} from './layouts.model';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})

export class LayoutComponent implements OnInit, AfterViewInit,OnDestroy {
  subscription: Subscription;
  // layout related config
  layoutType: string;
  layoutwidth: string;
  topbar: string;
  uid = localStorage.getItem('uid');
  public data1: any;
  constructor(private eventService: EventService, private chat: ChatService,private ss: SellerService,private chatsub:ChatsubjectService) {
  
   }

  ngOnInit() {
    this.notifcation();
    this.getSocketConnection();
     this.subscription=this.chat.getSocketChatMessages().subscribe((data:any)=>{
      if(data=='start_chat'){
    
        this.notifcation();
      }
      // this.notifyChat = data

      // setTimeout(() => {
      //   this.notifyChat = 'nochat'
      // }, 3000);
    })
    // default settings
    this.layoutType = LAYOUT_HORIZONTAL;
    this.layoutwidth = LAYOUT_WIDTH;
    this.topbar = TOPBAR;

    // listen to event and change the layout, theme, etc
    this.eventService.subscribe('changeLayout', (layout) => {
      this.layoutType = layout;
    });

    this.LayoutWidth(this.layoutwidth);

    this.eventService.subscribe('changeWidth', (width) => {
      this.layoutwidth = width;
      this.LayoutWidth(this.layoutwidth);
    });

   
  }
  notifyChat='nochat';
  ngAfterViewInit() {
   

  }

  notifcation() {
    // alert("lop")
    this.ss.getNotifications(this.uid).subscribe((res: any) => {
      if (res.code === 1) {
        // this.totalUnreadList = res.result[0].totalunread; 
        this.data1 = res.result[0].totalunread;
        this.chatsub.myMethod(this.data1)
      


      }
    })
  }


  LayoutWidth(width: string) {
    switch (width) {
      case "fluid":
        document.body.setAttribute("data-layout-size", "fluid");
        document.body.classList.remove("vertical-collpsed");
        document.body.removeAttribute("data-layout-scrollable");
        break;
      case "boxed":
        document.body.setAttribute("data-layout-size", "boxed");
        document.body.classList.add("vertical-collpsed");
        document.body.removeAttribute("data-layout-scrollable");
        break;
      case "scrollable":
        document.body.removeAttribute("data-layout-size");
        document.body.setAttribute("data-layout-scrollable", "true");
        document.body.setAttribute("data-layout-size", "fluid");
        document.body.classList.remove("right-bar-enabled", "vertical-collpsed");
      default:
        document.body.setAttribute("data-layout-size", "fluid");
        break;
    }
  }

  /**
   * Check if the vertical layout is requested
   */
  isVerticalLayoutRequested() {
    return this.layoutType === LAYOUT_VERTICAL;
  }

  /**
   * Check if the horizontal layout is requested
   */
  isHorizontalLayoutRequested() {
    return this.layoutType === LAYOUT_HORIZONTAL;
  }

  getSocketConnection(){
    this.chat.socketConnection();
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
 }
}
