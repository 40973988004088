import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs-compat";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root"
})
export class FetchDataService {
    baseUrl = environment.baseUrl;
    constructor(private http: HttpClient) {}

    login(data): Observable<any> {
        return this.http.post(`${this.baseUrl}/v1/buyers/login`, data);
    }

    registerEmail(data): Observable<any> {
        return this.http.post(`${this.baseUrl}/v1/buyers/emailverify`, data);
    }
    verifyEmail(data): Observable<any> {
        return this.http.post(`${this.baseUrl}/v1/buyers/validatelink`, data);
    }
    signup(register): Observable<any> {
        return this.http.post(`${this.baseUrl}/v1/buyers/signup`, register);
    }
    forgetPassword(email): Observable<any> {
        return this.http.post(`${this.baseUrl}/v1/buyers/forgotpassword`, email);
    }
    resetPassword(data): Observable<any> {
        return this.http.post(`${this.baseUrl}/v1/buyers/resetpassword`, data);
    }
    changePassword(data): Observable<any> {
        return this.http.post(`${this.baseUrl}/v1/buyers/changepassword`, data);
    }

    getCountryCodes() {
        return this.http.get(`${this.baseUrl}/v1/buyers/getCountries`);
    }
    getTotalUnreadMsgs(userId){
        return this.http.get(`${this.baseUrl}/v1/sellers/totaluread?receiver_id=${userId}`);
    }
    getProfileList(uid,token):Observable<any>{
        return this.http.get(`${this.baseUrl}/v1/buyers/getprofile?id=${uid}&token=${token}`);
    }
}
