import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { FetchDataService } from "src/app/core/services/fetch-data.service";

@Component({
    selector: "app-verifymail",
    templateUrl: "./verifymail.component.html",
    // template: "./verifymail.component.html",
    styleUrls: ["./verifymail.component.scss"]
})
export class    VerifymailComponent implements OnInit {
    verifyEmailForm: FormGroup;
    verifyLink;
    constructor(private fb: FormBuilder, private fd: FetchDataService, private ad: ActivatedRoute, private router: Router) {}

    ngOnInit(): void {
        this.ad.queryParamMap.subscribe((params: Params) => {
            this.verifyLink = params.params.h;
            console.log(params);
            this.verifyEmail();
        });
    }

    verifyEmail() {
        const formData = new FormData();
        formData.append("h", this.verifyLink);
        this.fd.verifyEmail(formData).subscribe((res) => {
            console.log(res);
            if (res.code == 1) {
                this.router.navigate(["/register"], { queryParams: { email: res.data.email, id: res.data.id, type: res.data?.type } });
            }
        });
    }
}
