<body data-spy="scroll" data-target=".fixed-top">
    <div class="mb-3">
        <!-- Navigation -->
        <nav class="navbar navbar-expand-lg fixed-top navbar-light">
          <div class="container">
  
              <!-- Text Logo - Use this if you don't have a graphic logo -->
              <!-- <a class="navbar-brand logo-text page-scroll" href="index.html">Kora</a> -->
  
              <!-- Image Logo -->
              <a class="navbar-brand logo-image" href="">
                  <img src="assets/images/connekkt-web-logo-black.png" alt="alternative"></a>
  
              
  
            
          </div> <!-- end of container -->
      </nav> <!-- end of navbar -->
      <!-- end of navigation -->
    </div>
  
    <div class="container mt-3">
   <div class="checkout-tabs">
    <div class="row">
        <div class="col-12">
          <div class="page-title-box d-flex align-items-end justify-content-end ">
            <h4 class="mb-0 font-size-18">Faq's</h4>
           
          </div>
        </div>
      </div>
          <div class="row mt-3">
              <div class="col-lg-2">
              <ul ngbNav #nav="ngbNav" [activeId]="1" class="flex-column nav-pills">
                <li [ngbNavItem]="1">
                  <a ngbNavLink>
                    <i class="bx bx-question-mark d-block check-nav-icon mt-4 mb-2"></i>
                    <p class="fw-bold mb-4  ">General Questions</p>
                  </a>
                  <ng-template ngbNavContent>
                    <div class="card">
                      <div class="card-body">
                        <div class="fade active show" id="v-pills-gen-ques">
                          <h4 class="card-title mb-5">General Questions</h4>
                          <div class="faq-box media mb-4">
                            <div class="faq-icon me-3">
                              <i class="bx bx-help-circle font-size-20 text-success"></i>
                            </div>
                            <div class="media-body">
                              <h5 class="font-size-15">What is Lorem Ipsum?</h5>
                              <p class="text-muted">New common language will be more simple and regular than the existing
                                European
                                languages. It will be as simple as occidental.</p>
                            </div>
                          </div>
                          <div class="faq-box media mb-4">
                            <div class="faq-icon me-3">
                              <i class="bx bx-help-circle font-size-20 text-success"></i>
                            </div>
                            <div class="media-body">
                              <h5 class="font-size-15">Where does it come from?</h5>
                              <p class="text-muted">Everyone realizes why a new common language would be desirable one could
                                refuse
                                to pay expensive translators.</p>
                            </div>
                          </div>
                          <div class="faq-box media mb-4">
                            <div class="faq-icon me-3">
                              <i class="bx bx-help-circle font-size-20 text-success"></i>
                            </div>
                            <div class="media-body">
                              <h5 class="font-size-15">Where can I get some?</h5>
                              <p class="text-muted">If several languages coalesce, the grammar of the resulting language is
                                more
                                simple and regular than that of the individual languages.</p>
                            </div>
                          </div>
                          <div class="faq-box media mb-4">
                            <div class="faq-icon me-3">
                              <i class="bx bx-help-circle font-size-20 text-success"></i>
                            </div>
                            <div class="media-body">
                              <h5 class="font-size-15">Why do we use it?</h5>
                              <p class="text-muted">Their separate existence is a myth. For science, music, sport, etc, Europe
                                uses
                                the same vocabulary.</p>
                            </div>
                          </div>
                          <div class="faq-box media">
                            <div class="faq-icon me-3">
                              <i class="bx bx-help-circle font-size-20 text-success"></i>
                            </div>
                            <div class="media-body">
                              <h5 class="font-size-15">Where can I get some?</h5>
                              <p class="text-muted">To an English person, it will seem like simplified English, as a skeptical
                                Cambridge friend of mine told me what Occidental</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </li>
                <li [ngbNavItem]="2">
                  <a ngbNavLink>
                    <i class="bx bx-check-shield d-block check-nav-icon mt-4 mb-2"></i>
                    <p class="fw-bold mb-4">Privacy Policy</p>
                  </a>
                  <ng-template ngbNavContent>
                    <div class="card">
                      <div class="card-body">
                        <div class="tab-pane">
                          <h4 class="card-title mb-5">Privacy Policy</h4>
      
                          <div class="faq-box media mb-4">
                            <div class="faq-icon me-3">
                              <i class="bx bx-help-circle font-size-20 text-success"></i>
                            </div>
                            <div class="media-body">
                              <h5 class="font-size-15">Where does it come from?</h5>
                              <p class="text-muted">Everyone realizes why a new common language would be desirable one could
                                refuse
                                to pay expensive translators.</p>
                            </div>
                          </div>
                          <div class="faq-box media mb-4">
                            <div class="faq-icon me-3">
                              <i class="bx bx-help-circle font-size-20 text-success"></i>
                            </div>
                            <div class="media-body">
                              <h5 class="font-size-15">Where can I get some?</h5>
                              <p class="text-muted">To an English person, it will seem like simplified English, as a skeptical
                                Cambridge friend of mine told me what Occidental</p>
                            </div>
                          </div>
                          <div class="faq-box media mb-4">
                            <div class="faq-icon me-3">
                              <i class="bx bx-help-circle font-size-20 text-success"></i>
                            </div>
                            <div class="media-body">
                              <h5 class="font-size-15">What is Lorem Ipsum?</h5>
                              <p class="text-muted">New common language will be more simple and regular than the existing
                                European
                                languages. It will be as simple as occidental.</p>
                            </div>
                          </div>
                          <div class="faq-box media mb-4">
                            <div class="faq-icon me-3">
                              <i class="bx bx-help-circle font-size-20 text-success"></i>
                            </div>
                            <div class="media-body">
                              <h5 class="font-size-15">Why do we use it?</h5>
                              <p class="text-muted">Their separate existence is a myth. For science, music, sport, etc, Europe
                                uses
                                the same vocabulary.</p>
                            </div>
                          </div>
                          <div class="faq-box media">
                            <div class="faq-icon me-3">
                              <i class="bx bx-help-circle font-size-20 text-success"></i>
                            </div>
                            <div class="media-body">
                              <h5 class="font-size-15">Where can I get some?</h5>
                              <p class="text-muted">If several languages coalesce, the grammar of the resulting language is
                                more
                                simple and regular than that of the individual languages.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </li>
                <li [ngbNavItem]="3">
                  <a ngbNavLink>
                    <i class="bx bx-support d-block check-nav-icon mt-4 mb-2"></i>
                    <p class="fw-bold mb-4">Support</p>
                  </a>
                  <ng-template ngbNavContent>
                    <div class="card">
                      <div class="card-body">
                        <div class="tab-pane">
                          <h4 class="card-title mb-5">Support</h4>
      
                          <div class="faq-box media mb-4">
                            <div class="faq-icon me-3">
                              <i class="bx bx-help-circle font-size-20 text-success"></i>
                            </div>
                            <div class="media-body">
                              <h5 class="font-size-15">Where can I get some?</h5>
                              <p class="text-muted">To an English person, it will seem like simplified English, as a skeptical
                                Cambridge friend of mine told me what Occidental</p>
                            </div>
                          </div>
                          <div class="faq-box media mb-4">
                            <div class="faq-icon me-3">
                              <i class="bx bx-help-circle font-size-20 text-success"></i>
                            </div>
                            <div class="media-body">
                              <h5 class="font-size-15">Where does it come from?</h5>
                              <p class="text-muted">Everyone realizes why a new common language would be desirable one could
                                refuse
                                to pay expensive translators.</p>
                            </div>
                          </div>
      
                          <div class="faq-box media mb-4">
                            <div class="faq-icon me-3">
                              <i class="bx bx-help-circle font-size-20 text-success"></i>
                            </div>
                            <div class="media-body">
                              <h5 class="font-size-15">Why do we use it?</h5>
                              <p class="text-muted">Their separate existence is a myth. For science, music, sport, etc, Europe
                                uses
                                the same vocabulary.</p>
                            </div>
                          </div>
                          <div class="faq-box media mb-4">
                            <div class="faq-icon me-3">
                              <i class="bx bx-help-circle font-size-20 text-success"></i>
                            </div>
                            <div class="media-body">
                              <h5 class="font-size-15">Where can I get some?</h5>
                              <p class="text-muted">If several languages coalesce, the grammar of the resulting language is
                                more
                                simple and regular than that of the individual languages.</p>
                            </div>
                          </div>
      
                          <div class="faq-box media">
                            <div class="faq-icon me-3">
                              <i class="bx bx-help-circle font-size-20 text-success"></i>
                            </div>
                            <div class="media-body">
                              <h5 class="font-size-15">What is Lorem Ipsum?</h5>
                              <p class="text-muted">New common language will be more simple and regular than the existing
                                European
                                languages. It will be as simple as occidental.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </li>
              </ul>
            </div>
            <div class="col-lg-10">
              <div [ngbNavOutlet]="nav"></div>
            </div>
          </div>
        </div>
      </div>
      
  
  
    
  
  
    
  
     
  
      
  
  
  
     
  
  
    
  
  
     
  
  
   
  
  
    
  
  
     
  
  
    
  
  
      <!-- Footer -->
      <div class="footer">
          <div class="container">
              <div class="row">
                  <div class="col-lg-12">
                      <div class="footer-col first">
                          <h6>About Connekkt Media</h6>
                          <p class="p-small">Blockchain powered Video/Audio Content Rights Marketplace.
                              World’s only Assisted sales platform for Digital Media Content Rights
                          </p>
                      </div> <!-- end of footer-col -->
                      <div class="footer-col second">
                          <h6>Useful Links</h6>
                          <ul class="list-unstyled li-space-lg p-small">
                              <li><a href="terms.html">Terms & Conditions</a><br/> <a href="privacy.html">Privacy
                                      Policy</a></li>
                              <!-- <li>Useful: <a href="#">Colorpicker</a>, <a href="#">Icon Library</a>, <a
                                      href="#">Illustrations</a></li>
                              <li>Menu: <a class="page-scroll" href="#header">Home</a>, <a class="page-scroll"
                                      href="#intro">Intro</a>, <a class="page-scroll" href="#features">Features</a>, <a
                                      class="page-scroll" href="#details">Details</a></li> -->
                          </ul>
                      </div> <!-- end of footer-col -->
                      <div class="footer-col third">
                          <span class="fa-stack">
                              <a href="#your-link">
                                  <i class="fas fa-circle fa-stack-2x"></i>
                                  <i class="fab fa-facebook-f fa-stack-1x"></i>
                              </a>
                          </span>
                          <span class="fa-stack">
                              <a href="#your-link">
                                  <i class="fas fa-circle fa-stack-2x"></i>
                                  <i class="fab fa-twitter fa-stack-1x"></i>
                              </a>
                          </span>
                          <span class="fa-stack">
                              <a href="#your-link">
                                  <i class="fas fa-circle fa-stack-2x"></i>
                                  <i class="fab fa-pinterest-p fa-stack-1x"></i>
                              </a>
                          </span>
                          <span class="fa-stack">
                              <a href="#your-link">
                                  <i class="fas fa-circle fa-stack-2x"></i>
                                  <i class="fab fa-instagram fa-stack-1x"></i>
                              </a>
                          </span>
                          <p class="p-small">We would love to hear from you <a
                                  href="mailto:help@connekkt.media"><strong>support@connekkt.media</strong></a>
                                  <br/><a
                                      href="tel:+4407466 31893"><strong>+44 07466 331893</strong></a></p>
  
                      </div> <!-- end of footer-col -->
                  </div> <!-- end of col -->
              </div> <!-- end of row -->
  
               <!-- Copyright -->
      <div class="copyright">
          <div class="container">
              <div class="row">
                  <div class="col-lg-12">
                      <p class="p-small">Copyright © <a href="#your-link">Connekkt Media</a></p>
                  </div> <!-- end of col -->
              </div> <!-- enf of row -->
          </div> <!-- end of container -->
      </div> <!-- end of copyright -->
      
          </div> <!-- end of container -->
      </div> <!-- end of footer -->
      <!-- end of footer -->
  
     
  </body>



