import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, NgForm, Validators, FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { SellerService } from "src/app/core/services/seller.service";
import Swal from 'sweetalert2';
import { map } from 'rxjs/operators';
import { FetchDataService } from 'src/app/core/services/fetch-data.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
@Component({
  selector: 'app-sale-support',
  templateUrl: './sale-support.component.html',
  styleUrls: ['./sale-support.component.scss']
})
export class SaleSupportComponent implements OnInit {
  // baseUrl = environment.mainUrl;
  contactForm:FormGroup;
  countryCodesList = [];
  submitted = false;
  error = '';
  errorMessage = '';
  id = localStorage.getItem("uid");
  type;


  data: string[] = [];
 
  constructor(private modalService: NgbModal,private fb:FormBuilder,private ss: SellerService,private fd:FetchDataService,private recaptchaV3Service: ReCaptchaV3Service,private http: HttpClient) { }

  ngOnInit(): void {
  
    this.getCountryCodes();
    this.contactForm = this.fb.group({
      name: ["",[Validators.required]],
      email: ["",[Validators.required, Validators.email]],
      country:["", Validators.required],
      country_code: ["", Validators.required],
      number: ["",[Validators.required]],
      message: ["",[Validators.required]],
      services: ["",[Validators.required]],
      recaptchaReactive:['',[Validators.required]]
      
   
    });
  
    
   
  }


   addTokenLog(message: string, token: string | null) {
    
  
    this.data.push(`${message}: ${this.formatToken(token)}`);
  }
  formatToken(token: string | null) {
  
    return token !== null
      ? `${token.substr(0, 7)}...${token.substr(-7)}`
      : 'null';
   
  }
  getCountryCodes() {
    this.fd
        .getCountryCodes()
        .pipe(
            map((data: any) => {
                this.countryCodesList = data.result;
               
                
               
            })
        )
        .subscribe();
}
  isNumber(evt) {
    evt = evt ? evt : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    return true;
  }
  onSelectCountry(item) {
   
    let indx = this.countryCodesList.findIndex((m) => m.name === item);
    this.contactForm.patchValue({
        country_code: this.countryCodesList[indx]["phonecode"]
    });
  }
 /**
   * Open small modal
   * @param largeDataModal small modal data
   */
  smallModal(largeDataModal: any) {
    this.modalService.open(largeDataModal, { size: 'lg', centered: true });
  }
  onSubmit() {
    this.type=3;
    this.submitted = true;

    // stop here if form is invalid
    if (this.contactForm.invalid) {
      return;
    } else {
      const formData = new FormData();
      // formData.append("id", this.id)
      formData.append("type", this.type);
      formData.append("user_name", this.contactForm.value.name);
      formData.append("email", this.contactForm.value.email);
      formData.append("country", this.contactForm.get("country").value);
      formData.append("country_code", this.contactForm.get("country_code").value);
      formData.append("phone_number", this.contactForm.value.number);
      formData.append("message", this.contactForm.value.message);
      formData.append("services", this.contactForm.value.services);
      this.ss.contactUSForm(formData).subscribe((res: any) => {
        if (res.code === 1) {
          Swal.fire(
            'Thankyou For Contact us',

          );
          this.modalService.dismissAll();
        
        } else {
          this.errorMessage = res.result;
        }
      })

    }
  }

}
