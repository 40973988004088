import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';

import { LoaderService } from './loader.service';
import { catchError, finalize, map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ReqInterceptor implements HttpInterceptor {

  constructor(private loader: LoaderService) {

  }

  // intercept(req: HttpRequest<any>, next: HttpHandler) {
  //   this.loader.show();
  //   console.log('asdf', req);
  //   return next.handle(req).pipe(
  //     finalize(() => this.loader.hide())
  //   );

  // }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!request.url.match("reaction") && !request.url.match("totalunread") && !request.url.includes('getChat') && !request.url.includes('readmark') && !request.url.includes('bigboy') && !request.url.includes('setChat') && !request.url.includes('watchlistdata')) {
      this.loader.setLoading(true, request.url);
    }
    return next.handle(request)
      .pipe(catchError((err) => {
        this.loader.setLoading(false, request.url);
        return err;
      }))
      .pipe(map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
        if (evt instanceof HttpResponse) {
          this.loader.setLoading(false, request.url);
        }
        return evt;
      }));
  }
}